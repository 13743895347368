/***
PAGES
- index
- feedback
- FAQs
- Best Practices
- Business
- Splash
***/
/***** INDEX *****/
.bg-feedback-guests {
	background-image:url('../img/bg-feedback-guests.jpg');
	background-size:cover;
}
/***** FEEDBACK *****/
img.shift-left {
	-ms-transform: translateX(-25px);
   	-webkit-transform: translateX(-25px);
    transform: translateX(-25px);
}
form.feedback {
	max-width:420px;
	height:500px;
	min-height:420px;
	margin:auto;
	border-radius:8px;
	position:relative;
	overflow:hidden;
}
form.feedback.text-me { height:auto; }
form.feedback .wrapper {
	padding:3em 0 6em;
	position:absolute;
	right:-20px;
	overflow-y: hidden;
	height:100%;
	width: 440px;
}
form.feedback .pad { padding:0 1.5em; }
form.feedback .results { display:none; }
form.feedback .error-message {
	display:none;
	color:#D82117;
	font-size:.9em;
	padding:5px 1.5em;
}
form.feedback textarea + .error-message {
	padding:2px 5% 5px;
}
form .step {
	opacity:0;
	z-index:-1;
	position:absolute;
	top:0; left:0;
	width:420px;
	height:auto;
	max-height:0; overflow:hidden;
	padding:3em 0;
	-webkit-transition: opacity 1s ease-in-out;
	-moz-transition: opacity 1s ease-in-out;
	-o-transition: opacity 1s ease-in-out;
	transition: opacity 1s ease-in-out;
}
form .step.active {
	opacity:1;
	z-index:2;
	max-height:10000px;
}
form.not-fixed .step {
	position:relative;
	top:auto; left:auto;
	width:auto;
	padding:0;
}
form.not-fixed .step.active {
	display:block;
	padding:3em 0;
}
form.feedback input[type="text"],
form.feedback input[type="email"] {
	background-color:#294a69;
	height:50px;
	width:100%;
	font-size:.9em;
	padding-left:1.5em;
	padding-right:1.5em;
	outline:none;
	border:none;
}
form.feedback textarea {
	width:100%;
	border-radius:4px;
	padding:.5em;
	height:6em;
	outline:none;
	border:none;
	color:#272727;
}
form.feedback ::-webkit-input-placeholder { color: #8ba6c3; }
form.feedback :-moz-placeholder { color: #8ba6c3; }
form.feedback ::-moz-placeholder { color: #8ba6c3; }
form.feedback :-ms-input-placeholder { color: #8ba6c3; }
/*form .btn-wrap {
	width:420px;
	text-align:center;
	position:absolute;
	bottom:1.5em; left:0;
	z-index:3;
}*/
form.feedback .btn {
	font-size:.9em;
	font-weight:100;
	text-transform:capitalize;
	width:90%;
	margin-left:5%;
	margin-right:5%;
}
form.feedback .back {
	color:#8ba6c3;
	position:absolute;
	top:1em; left:1em;
	opacity:.75;
	cursor:pointer;
	z-index:99;
}
form.feedback .back:hover {
	opacity:1;
}
form.feedback .results {
	list-style:none;
}
form.feedback .results li {
	margin:.5em 1em 1em;
	font-size:.9em;
	cursor:pointer;
}
form.feedback .results li:hover,
form.feedback .results li:focus {
	color:#ef4b23;
}
form.feedback .title {
	font-size:.9em;
	padding:.75em 1.5em;
	margin-left:1.5em;
	margin-right:1.5em;
	border-top:1px solid #8ba6c3;
	border-bottom:1px solid #8ba6c3;
	text-align:center;
}
form.feedback .question {
	font-size:1.1em;
	text-align: center;
	padding:.5em 10%;
	position:relative;
}
form.feedback .question .step-number {
	position:absolute;
	left:10%;
	top:50%;
	width:24px;
	height:24px;
	margin:-12px 0 0 -24px;
	border-radius:100px;
	font-size:14px;
	line-height:24px;
	text-align:center;
	background:#ef4b23;
}

form.feedback input[type="radio"] { display:none; }
form.feedback .kriddikal-score {
	background:#294a69;
	height:50px;
}
form.feedback .kriddikal-score input[type="radio"] + label {
	width:10%;
	text-align:center;
	float:left;
	font-size:1.1em;
	font-weight:normal;
	line-height:50px;
	border-left:1px solid #001128;
	border-right:1px solid #24619B;
	cursor:pointer;
}
form.feedback .kriddikal-score input[type="radio"]:first-child + label { border-left:none !important; }
form.feedback .kriddikal-score label:last-child { border-right:none !important; }
form.feedback .kriddikal-score input[type="radio"]:checked + label,
form.feedback .kriddikal-score input[type="radio"]:hover + label {
	background:#ef4b23;
}
form.feedback .rating {
	background:#294a69;
	padding:5px 1.5em;
	height:50px;
	clear:both;
	margin-bottom:2px;
}
form.feedback .rating label { margin:0; }
form.feedback .rating > label {
	float:left;
	width:120px;
	line-height:40px;
	border-right:1px solid #001128;
	font-weight:normal;
	position:relative;
}
form.feedback .rating > label:after {
	content:"";
	height:40px;
	width:1px;
	background:#24619B;
	position:absolute;
	top:0; right:0;
}
form.feedback .rating .values {
	overflow:hidden;
	display:inline-block;
	text-align:right;
	float:right;
}
form.feedback .rating .values label {
	padding:0 4px;
	color:#001128;
	font-size:24px;
	cursor:pointer;
	line-height:40px;
}
form.feedback .rating .values label:hover,
form.feedback .rating .values label:hover ~ label,
form.feedback .rating .values label:checked ~ label,
form.feedback .rating .values:hover label:hover,
form.feedback .rating .values:hover label:hover ~ label,
form.feedback .rating .values input[type="radio"]:checked ~ label {
	color:#ef4b23;
}
form.feedback .rating .values label,
form.feedback .rating .values:hover label {
  position: relative;
  float: right;
  color:#001128;
}
form.feedback .picture {
	min-height:600px;
	background-image:url("../img/sample-receipt.jpg");
	background-size:cover;
	background-position:center center;
}
form.feedback .picture:before {
	position:absolute;
	width:100%;
	height:100%;
	border-top:100px solid rgba(0,0,0,.25);
	border-bottom:100px solid rgba(0,0,0,.25);
	border-left:50px solid rgba(0,0,0,.25);
	border-right:50px solid rgba(0,0,0,.25);
	content:"";
	margin-top:-3em;
}
form.feedback .picture-wrap {
	min-height:504px;
	position:relative;
}
form.feedback .picture .notice {
	position:absolute;
	top:50%;
	left:50%;
	margin:-30px;
	margin-left:-120px;
}
form.feedback .picture .btn.next {
	position:absolute;
	bottom:-1em;
	left:0;
}
form.feedback .picture input[type="file"] {
	display:none;
}
form.feedback .picture input[type="file"] + label {
	position:absolute;
	height:100%;
	width:100%;
	top:0; left:0;
}
form.feedback .notice {
	display:block;
	background-color:#294a69;
	text-align: center;
	margin:auto;
	max-width:300px;
	padding:.5em 1em;
	border-radius:12px;
}
@media screen and (max-width:450px) {
	form.feedback {
		max-width:315px;
		height:480px;
	}
	form.feedback .wrapper {
		padding:2em 0 4em;
		width: 335px;
	}
	form.feedback p { font-size:90%; line-height:1.33; }
	form.feedback .pad:not(.results) { padding:0 1em; display:block; }
	form.feedback .error-message { padding:5px 1em; }
	form .step {
		width:315px;
		padding:2.5em 0;
	}
	form.feedback input[type="text"],
	form.feedback input[type="email"] {
		height:40px;
		padding-left:1em;
		padding-right:1em;
	}
	form.feedback textarea {
		height:4em;
	}
	form.feedback .back {
		top:.75em; left:.75em;
	}
	form.feedback .results li {
		margin:.5em 0em .75em;
		font-size:.9em;
	}
	form.feedback .title {
		font-size:.9em;
		padding:.75em 1em;
		margin-left:1em;
		margin-right:1em;
	}
	form.feedback .question {
		font-size:1em;
		padding:.5em 10%;
	}
	form.feedback .question .step-number {
		width:20px;
		height:20px;
		margin:-10px 0 0 -22px;
		font-size:12px;
		line-height:20px;
	}
	form.feedback .kriddikal-score {
		height:40px;
	}
	form.feedback .kriddikal-score input[type="radio"] + label {
		font-size:1em;
		line-height:40px;
	}
	form.feedback .rating {
		padding:5px 1em;
		height:40px;
	}
	form.feedback .rating > label {
		width:100px;
		line-height:30px;
	}
	form.feedback .rating > label:after {
		height:30px;
	}
	form.feedback .rating .values label {
		padding:0 4px;
		font-size:20px;
		line-height:30px;
	}
	form.feedback .picture {
		min-height:450px;
	}
	form.feedback .picture:before {
		border-top:80px solid rgba(0,0,0,.25);
		border-bottom:80px solid rgba(0,0,0,.25);
		border-left:40px solid rgba(0,0,0,.25);
		border-right:40px solid rgba(0,0,0,.25);
		margin-top:-2.5em;
	}
	form.feedback .picture-wrap {
		min-height:380px;
	}
	form.feedback .picture .notice {
		margin-left:-117px;
	}
	form.feedback .notice {
		max-width:260px;
		font-size:90%;
	}
	form.feedback img.logo { max-width:200px; }
	form.feedback .breaker.shrink-mobile { height:1em; }
	form.feedback .with-icon input {
		padding-left:2.5em !important;
	}
	form.feedback .with-icon:before {
		top:9px; left:7px;
		font-size:16px;
		padding-right:6px;
		width:22px;
	}
}


/***** Best Practices *****/
ol.practices { padding-left:18px; }
ol.practices li {
	margin-top:1em;
}

/***** Business *****/
.bg-traffic {
	background-image:url("../img/bg-traffic.jpg");
	background-position:center center;
	background-size:cover;
}
/* pricing table */
.pricing-table {
	max-width:600px;
	margin:auto;
}
.pricing-table .header {
	overflow:hidden;
}
.pricing-table .type {
	font-weight:bold;
	text-align:center;
	float:left;
	display:inline-block;
	padding:0 .5em;
}
.pricing-table .type.last {
	float:right;
}
.pricing-table .type .subtitle {
	font-weight:normal;
	font-size:16px;
}
.pricing-table .body {
	clear:both;
}
.pricing-table .item {
	display:flex;
	flex-direction:row;
	border:2px solid #57739f;
	border-top:none;
	text-align:center;
}
.pricing-table .item:first-child {
	border-top:2px solid #57739f;
}
.pricing-table .item p {
	width:100%;
	color:#fff;
	border-left:2px solid #57739f;
	border-right:2px solid #57739f;
	margin:0;
	padding:1em;
	font-size:1.5em;
	line-height:1.25;
}
.pricing-table .item span {
	width:140px;
	padding:10px;
}
.pricing-table .item p + span {
	order:-1;
}
.pricing-table .item span img {
	max-height:50px;
}
.pricing-table .item span:before {
	content:"";
	display:inline-block;
	height:100%;
	width:0;
	vertical-align:middle;
}
.pricing-table .item.actions {
	border:none;
	padding-top:1em;
}
.pricing-table .item.actions p {
	border:none;
	font-size:2em;
	font-weight:bold;
	text-transform:uppercase;
	padding:0;
}
.pricing-table .item.actions span {
	padding:2px;
}
.pricing-table .item.actions span .btn {
	width:100%;
	font-size:14px;
}
@media screen and (max-width:599px) {
	.pricing-table .item p {
		font-size:1em;
		padding:.5em .75em;
	}
	.pricing-table .item span {
		width:80px;
		padding:8px;
	}
	.pricing-table .item.actions {
		display:block;
	}
	.pricing-table .item.actions span {
		width:50%;
		float:left;
		padding:1em;
	}
}
@media screen and (max-width:479px) {
	.pricing-table .type {
		font-size:1.5em;
	}
	.pricing-table .item span {
		width:60px;
		padding:8px;
	}
	.pricing-table .item.actions p {
		font-size:1.5em;
	}
	.pricing-table .item.actions span {
		padding:.5em;
	}
}
/*** Claim Business Search Results ***/
.search-results {
	list-style:none;
	padding:0 1em;
	max-height:400px;
	overflow-y:hidden;
}
.search-results > li {
	padding:1em .5em;
	border-bottom:1px solid #8ba6c3;
	cursor:pointer;
}
.search-results > li:hover {
	font-weight:bold;
}
#selected-business .readonly {
	background:rgba(255,255,255,.1);
}

/*** Why Kriddik? stats ***/
#why-kriddik {
	max-width:700px;
	border-radius:4px;
	margin:auto;
	padding:1em;
	padding-left:0;
}
#why-kriddik .item {
	display:none;
}
#why-kriddik .item.active {
	display:block;
}
#why-kriddik .number {
	display:inline-block;
	vertical-align:middle;
	text-align: center;
	font-weight:bold;
	font-size:3em;
	width:18%;
}
#why-kriddik .body {
	display:inline-block;
	vertical-align:middle;
	width:80%;
	margin-bottom:0;
	text-align:left;
	font-size:1.25em;
}
#why-kriddik .source {
	display:block;
	text-align:right;
	font-style:italic;
	padding-top:.25em;
}
@media screen and (max-width:767px) {
	#why-kriddik {
		max-width:90%;
		margin:0 5%;
		padding-left:1em;
	}
	#why-kriddik .number {
		width:28%;
	}
	#why-kriddik .body {
		width:70%;
	}
}
@media screen and (max-width:599px) {
	#why-kriddik {
		padding:1em .5em;
	}
	#why-kriddik .number {
		width:18%;
	}
	#why-kriddik .body {
		width:75%;
	}
	#why-kriddik .number {
		font-size:2em;
	}
	#why-kriddik .body {
		font-size:1em;
	}
}
@media screen and (max-width:479px) {
	#why-kriddik .number {
		width:100%;
	}
	#why-kriddik .body {
		width:100%;
		text-align:center;
	}
	#why-kriddik .source {
		text-align:center;
	}
}
/***** Splash *****/
body.splash {
	padding-top:0;
}
.splash #header {
	padding:1em 0 1.5em;
	border-bottom:6px solid #ef4b23;
	box-shadow:none;
}
.splash #header .border-extension {
	position:absolute;
	left:50%;
	bottom:-36px;
	width:47px;
	margin-left:45px;
}
.splash .subtitle {
	color:#f5f5f5;
	font-size:2.25em;
	margin:.25em 0 0;
	text-transform:none;
}
.splash h1 {
	font-size:4em;
}
.splash h1 .large {
	font-size:175%;
	vertical-align:middle;
	display:inline-block;
}
.splash h1 img {
	margin-left:-140px;
}
.splash .wrap-form,
.splash .wrap-thanks {
	background:#fff;
	max-width:1000px;
	padding:2em 3em 1em;
	margin:auto;
}
.splash .shift-up {
	margin-top:-1em;
}
.splash .wrap-thanks {
	padding:0;
	max-width:800px;
	margin-top:-3em;
	z-index:2;
	position:relative;
}
.splash .wrap-thanks > img {
	display:block;
	width:100%;
}
.splash .wrap-thanks .content {
	padding:2em;
}
.splash .wrap-thanks .skinny {
	max-width:600px;
}
.splash .border-dots {
	margin-bottom:11px;
}
.splash .we-want-you {
	background:#57739f;
	margin-top:-22px;
}
.splash .we-want-you .container {
	max-width:900px;
}
.splash .we-want-you img.mod {
	float:left;
	width:55%;
}
.splash .we-want-you img.ss {
	float:right;
	width:45%;
	margin-top:-10px;
}
@media screen and (max-width:767px) {
	.splash h1 {
		font-size:3em;
	}
	.splash h1 > img {
		max-width:90px;
		margin-left:-90px;
	}
}
@media screen and (max-width:599px) {
	.splash #header img {
		max-width:240px;
	}
	.splash .subtitle {
		font-size:1.75em;
	}
	.splash h1 > img { display:none; }
	.splash .wrap-form, .splash .wrap-thanks {
		padding:1em;
	}
	.splash .wrap-thanks .content {
		padding:1em 0;
	}
	.splash .we-want-you img.mod {
		display:block;
		float:none;
		width:100%;
		margin:0 auto -100px;
		max-width:375px;
	}
	.splash .we-want-you img.ss {
		display:block;
		float:none;
		width:100%;
		margin:0 auto;
		max-width:375px;
	}
}
@media screen and (max-width:479px) {
	.splash h1 {
		font-size:2.5em;
	}
	.splash .we-want-you img.mod { margin-bottom:-90px; }
	.splash .download-icons.horizontal > a {
		width:100%;
		float:none;
	}
	.splash .download-icons.horizontal > a:first-child {
		margin-bottom:1em;
	}
}
/***
	these could be moved to custom.css
***/
.callout.bg-secondary {
	background-color:#ef4b23;
}
.closable-content.closed {
	display:none;
}
.border-dots {
	max-width:90%;
	margin:0 auto;
	overflow:hidden;
}
.border-dots.lt-gray {
	color:#ccc;
}
a.white:hover,
a.white:focus {
	color:#f5f5f5;
	text-decoration:none;
}