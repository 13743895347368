/***
MAIN COMPONENTS
- helpers
- header
- navbar
- footer
- content
- sidebar
- pieces
- slim version of site
***/

/*** HELPERS ***/
* {
	background-repeat:no-repeat;
}
body {
	background:#f5f5f5;
	color:#231f20;
	padding-top:121px;/* for fixed header */
	overflow-x:hidden;
}
@media screen and (max-width:767px) {
	body { padding-top:96px; }
}
.container {
	max-width:1080px;
	width:96%;
}
.section {
	/*padding:3em 0 4.5em;*/
	padding:3em 0;
}
.section.large { padding:4.5em 0; }
/*
.section-arrow + .section {
	padding-top:6em;
}
.section-arrow { text-align:center; height:0; }
.section-arrow img {
	margin-top:-42px;
}
*/
img { max-width:100%; }
.breaker { height:1.5em; clear:both; }
.breaker.small { height:.75em; }
.breaker.medium { height:1em; }
.breaker.large { height:3em; }
.shadow { box-shadow:0 0 12px 4px rgba(0,0,0,.25); }
.center { text-align:center; }
.icon-main { margin-bottom:1.5em; }
.icon-before { position:relative; display:inline-block; }
.icon-before > img.icon {
	position:absolute;
	left:-48px;
	width:32px;
}
.ntm { margin-top:0 !important; }
.nbm { margin-bottom:0 !important; }
.ntp { padding-top:0 !important; }
.nbp { padding-bottom:0 !important; }
.nlp { padding-left:0 !important; }
.nrp { padding-right:0 !important; }
.upper { text-transform:uppercase !important; }
.lower { text-transform:lowercase !important; }
.underline { text-decoration:underline; }
ol.lower-alpha { list-style:lower-alpha; }
ol.upper-alpha { list-style:upper-alpha; }
ol.steps { padding-left:20px; margin-top:1em; }
ol.steps li { margin-bottom:1em; }
ol.steps li:last-child { margin-bottom:0; }
div.hr {
	height:1px;
	background:rgba(0,0,0,.2);
	width:80%;
	margin:2em auto;
}
div.hr.skinny { max-width:300px; }
@media screen and (min-width:980px) {
	.md-center, .md-text-center { text-align:center; }
	.md-text-right { text-align:right; }
	.breaker.md { display:none; }
}
@media screen and (max-width:979px) {
	.md-remove-bg { background-image:none; }
}
@media screen and (min-width:768px) {
	.sm-center, .sm-text-center { text-align:center; }
	.sm-text-right { text-align:right; }
	.breaker.sm { display:none; }
	.sm-pull-right { float:right; }
}
@media screen and (max-width:767px) {
	.container { width:100%; }
	.sm-remove-bg { background-image:none; }
}
@media screen and (min-width:600px) {
	.breaker.xs { display:none; }
	.m-only { display:none !important; }
}
@media screen and (max-width:599px) {
	div[class^="col-xs-"] {
		width:100%;
	}
	.m-plus-only { display:none !important; }
	.xs-remove-bg { background-image:none; }
	.xs-remove-bg.multi-bg * { background-image:none; }
}
/*
LAYOUTS
*/
@media screen and (max-width:979px) {	
	div[class^="col-md"] + div[class^="col-md"] { margin-top:3em; }
}
@media screen and (max-width:767px) {	
	div[class^="col-sm"] + div[class^="col-sm"] { margin-top:3em; }
}
@media screen and (max-width:599px) {	
	div[class^="col-xs"] + div[class^="col-xs"] { margin-top:1.5em; }
	.form-col[class^="col-xs"] + .form-col[class^="col-xs"] { margin-top:15px; }
}
#main {
	width:77.5%;
	float:right;
	padding:3em 0 3em 1em;
	min-height:300px;
}
#main .section {
	padding: 3em 0 0;
}
#main .intro h1 { margin:0; }
#main .intro p:last-child { margin-bottom:0; }
#sidebar {
	width:22.5%;
	float:left;
	padding-right:1em;
	padding-top:3em;
	-webkit-transition: all 0.5s ease-in;
	-moz-transition:    all 0.5s ease-in;
	-ms-transition:     all 0.5s ease-in;
	transition:         all 0.5s ease-in;
}
#sidebar.up {
	margin-top:-50px;
}
#sidebar li {
	margin-bottom:10px;
}
#sidebar .nav > li {
	font-size:.9em;
}
#sidebar .nav > li > a {
	display:inline;
	padding:0;
}
#sidebar .nav li ul {
	display:none;
	padding-left:0;
	list-style:none;
	margin-top:5px;
}
#sidebar .nav li ul li {
	margin-bottom:5px;
	padding-left:10px;
}
#sidebar .nav li ul li a {
	font-weight:normal;
}
#sidebar .active {
	font-weight:bold;
	color:#ef4b23;
}
#sidebar .active .active {
	border-left:1px solid #ef4b23;
	padding-left:9px;
}
#sidebar .nav li.active ul { display:block; }
@media screen and (max-width:767px) {
	#main {
		width:100%;
		float:none;
		padding-left:0;
		padding-top:0;
	}
	#main > .section > .container {
		padding:0;
	}
	#sidebar {
		float:none;
		width:100%;
		padding:1.5em 0;
	}
	#sidebar .nav {
		position:relative;
		margin:0 -1%;
		width:100% !important;
	}
	#sidebar .download-link { display:none !important; }
	#sidebar .nav li {
		display:inline-block;
		padding:.5em 1em;
		margin:0 1% .5em;
		border:1px solid #dedede;
		border-radius:4px;
	}
	#sidebar .nav li:hover {
		background-color:#dedede;
	}
	#sidebar .nav li.back { border:none; padding:0; margin-bottom:0; }
	#sidebar .nav li.back:hover { background-color:transparent; }
}
#sidebar .download-link.first {
	padding-top:10px;
}
#sidebar .download-link img {
	max-width:150px;
}
/*
FONTS
*/
a:focus { color:#ef4b23; outline:none; }
h1, h2, h3, h4, h5, h6, p,
.h1, .h2, .h3, .h4, .h5, .h6 { margin-top:0; }
h1 {
	font-size:3em;
}
.h1.large {
	font-size:3.5em;
}
h2 {

}
h3 {

}
h4 {

}
h5 {

}
p {
	line-height:1.65;
}
p.large {
	font-size:1.25em;
}
sup {
	top: 8px;
	font-size: 12px;
	vertical-align: top;
}
h1 sup, .h1 sup { font-size: 50%; top:18px; }
.bold { font-weight:bold; }
.subtitle {
	display:block;
	font-size:85%;
}
.skinny {
	max-width:500px;
	margin-left:auto;
	margin-right:auto;
}
.skinny-medium {
	max-width:750px;
	margin-left:auto;
	margin-right:auto;
}
.special {
	text-transform: uppercase;
	font-weight:100;
}
.special span {
	display:inline-block;
	position:relative;
}
@media screen and (min-width:980px) {	
	.special span:before,
	.special span:after {
		content:"";
		position:absolute;
		top:50%;
		height:1px;
		margin-top:-1px;
		width:125px;
		background-color:#546e87;
	}
	.special span:before { right:100%; margin-right:1em; }
	.special span:after { left:100%; margin-left:1em; }
	.bg-white .special span:before,
	.bg-white .special span:after {
		background-color:#c3c3c3;
	}
	.bg-secondary .special span:before,
	.bg-secondary .special span:after {
		background-color:#f26f4f;
	}
}
@media screen and (max-width:599px) {
	h1 { font-size:2em; }
	h1.large { font-size:2.5em; }
	h2 { font-size:1.75em; }
	h3 { font-size:1.5em; }
}
/*
COLORS
- primary (dark blue) - #001128
- primary light - #294a69
- secondary (orange) - #ef4b23
- tertiary (blue) - #1a354f
- black - #272727
- gray - #6d6e71
- white - #f5f5f5
- blue on primary - #8ba6c3
- black on white - #231f20
- gray on white - #858585
*/
.bg-primary {background-color:#001128;}
.bg-primary-lt {background-color:#294a69;}
.bg-secondary {background-color:#ef4b23;}
.bg-tertiary {background-color:#1a354f;}
.bg-black {background-color:#272727;}
.bg-gray {background-color:#6d6e71;}
.bg-white {background-color:#f5f5f5;}
.primary {color:#001128;}
.primary-lt {color:#294a69;}
.secondary {color:#ef4b23;}
.tertiary {color:#1a354f;}
.tertiary-lt { color:#8ba6c3; }
.white {color:#f5f5f5;}
.gray { color:#6d6e71; }
.black { color:#231f20; }
/*
buttons
*/
button, .btn, .button {
	outline:none !important;
	text-transform:uppercase;
}
.btn.btn-main {
	padding:.5em 3em;
}
/*
backgrounds
*/
@media screen and (min-width:980px) {	
	.bg-businesses {
		background-image:url("../img/bg-businesses.png");
		background-position:center 25%;
	}
	.bg-businesses-gray-right {
		background-image:url("../img/bg-businesses-gray.png");
		background-position:right 85%;
	}
}
/*
callouts
*/
.callout {
	border-radius:4px;
	padding:1em;
	box-shadow:0 2px 5px 0px rgba(0,0,0,.25);
	background-color:#fff;
	margin-top:2em;
	margin-left:-1em;
	background-position:85% 65%;
	max-width:532px;
}
.callout.bg-primary  { background-color:#001128; }
.bg-tertiary .callout { background-color:#294a69; }
.callout.trophy {
	background-image:url("../img/callout-trophy.png");
}

/*
.callout.video {
	background-image:url("../img/callout-video.png");
}
*/

.callout.multi-bg {
	position:relative;
}
.callout.multi-bg .bg-piece {
	position:absolute;
	top:0; left:0;
	width:100%;
	height:100%;
	pointer-events:none;
}
.bg-arrow-right {
	background-image: url('../img/bg-arrow-right-white.png');
	background-position:2.5% 10%;
}
.bg-arrow-left {
	background-image: url('../img/bg-arrow-left-white.png');
	background-position:95% 10%;
}
.bg-arrow-left.bottom { background-position:90% 90%; }
.bg-excitement-right {
	background-image: url('../img/bg-excitement-right-white.png');
	background-position:85% 10%;
}
.callout.center { margin-left:auto; margin-right:auto; }
.callout.right { margin-left:auto; margin-right:-1em; }
.callout.bg-left { background-position:15% 65%; }
.callout h2 {
	font-weight:bold;
	font-size:2.5em;
}
.callout h3 {
	margin-top:0;
	font-weight:bold;
	margin-bottom:.25em;
}
.callout p {
	font-size:2em;
}
.callout p.small {
	font-size:1.5em;
}
.callout .btn {
	font-size:1em;
	padding:1em 2em .85em;
}
@media screen and (max-width:599px) {
	.callout {
		margin-left:0;
		max-width:100%;
	}
}
/*
sticky CTAs
*/
.sticky-cta {
	position:fixed;
	z-index:19;
	bottom:10px;
	left:10px;
	background-color:#001128;
	padding:15px 30px;
	border-radius:4px;
	color:#fff;
	text-align:center;
}
.sticky-cta .btn {
	font-size:12px;
}
@media screen and (max-width:599px) {
	.sticky-cta {
		bottom:0;
		left:0;
		box-shadow:none;
		border-radius:none;
		width:100%;
	}
}
/*
carousels
*/
.carousel-wrap {
	position:relative;
	margin:auto;
}
.carousel-wrap.small { max-width:600px; }
.carousel-content .author {
	display:block;
	margin-top:1em;
	font-style:italic;
}
.owl-carousel .owl-nav > div {
	position:absolute;
	top:10%;
	font-size:30px;
	font-weight:100;
	color:#f5f5f5;
	opacity:.25;
	-webkit-transform:scale(1,1.5);
	-moz-transform:scale(1,1.5);
	-ms-transform:scale(1,1.5);
	transform:scale(1,1.5);
	-webkit-transition: opacity 0.15s linear;
	-moz-transition:    opacity 0.15s linear;
	-ms-transition:     opacity 0.15s linear;
	transition:         opacity 0.15s linear;
}
.owl-carousel .owl-nav > div:hover { opacity:1; }
.owl-carousel .owl-nav > .owl-prev { left:-100px; }
.owl-carousel .owl-nav > .owl-next { right:-100px; }

@media screen and (max-width:767px) {	
	.owl-carousel .owl-nav > div { display:none; }
}

/*
featured images
*/
.featured-image {
	border:8px solid #ef4b23;
	border-radius:4px;
}
.featured-image img {
	width:100%;
}
.featured-image p {
	background:#001128;
	color:#fff;
	font-weight:bold;
	font-size:2em;
	text-align: center;
	padding:.5em;
	margin:0;
}
/*** HEADER ***/
.navbar {
	box-shadow:inset 0 -24px 12px -12px rgba(0,0,0,.25);
	font-weight:bold;
	text-transform:uppercase;
	font-size:12px;
}
#header .navbar-brand {
	padding:20px 12px;
}
.navbar-brand img {
	border-radius:12px;
	box-shadow:0 0 12px 4px rgba(0,0,0,.25);
	max-width:200px;
}
.navbar .navbar-nav li a {
	position: relative;
	overflow:hidden;
}
.navbar .cta button {
	font-size:12px;
	margin-top:-4px;
	text-transform:uppercase;
}
@media screen and (max-width:767px) {
	nav.navbar { min-height:90px; }
	nav .navbar-brand { height:auto; }
	nav .navbar-brand img {
		max-width:50px;
		border-radius:6px;
	}
	nav .navbar-toggle {
		margin-top:30px;
		margin-bottom:30px;
		color:#fff;
		border:none;
	}
	nav .navbar-toggle i {
		margin-right:4px;
	}
	.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
		background-color:#1a354f;
	}
}
@media screen and (min-width:768px) and (max-width:939px) {
	.navbar .navbar-nav .med-hide-extra { display:none; }
	.navbar .navbar-nav .med-show-extra { display:inline; }
}
@media screen and (min-width:768px) {
	.navbar .navbar-nav > li:not(.cta) > a:hover {
		color:#f2f2f2;
	}
	.navbar .navbar-nav > li:not(.cta) > a:before {
		content:" ";
		opacity:0;
		position:absolute;
		bottom:33.33%;
		left:0;
		width:100%;
		height:5px;
		border-radius:2px;
		background-color:#ef4b23;
		-webkit-transition: opacity 0.25s ease-in;
		-moz-transition:    opacity 0.25s ease-in;
		-ms-transition:     opacity 0.25s ease-in;
		transition:         opacity 0.25s ease-in;
	}
	.navbar .navbar-nav > li:not(.cta) > a:hover:before {
		opacity:1;
		-webkit-transition: opacity 0.25s ease-in;
		-moz-transition:    opacity 0.25s ease-in;
		-ms-transition:     opacity 0.25s ease-in;
		transition:         opacity 0.25s ease-in;
	}
	.navbar .navbar-nav > li {
		margin-left:5px;
	}
	.navbar .navbar-nav > li:first-child {margin-left:0;}
	/* dropdown menu */
	.dropdown-menu li:hover .sub-menu { visibility: visible; }
	.dropdown:hover .dropdown-menu { display: block; }
	.navbar-nav > li > .dropdown-menu {
		background-color:#1a354f;
		margin-top:-30px;
	}
	nav.navbar.shrink .navbar-nav > li > .dropdown-menu {
		margin-top:-10px;
	}
	.navbar-nav > li > .dropdown-menu li a {
		padding:10px 20px;
		color:#fff;
		font-weight:bold;
		font-size:14px;
	}
	.navbar-nav > li > .dropdown-menu li a:hover,
	.navbar-nav > li > .dropdown-menu li a:focus {
		background-color:#001128;
		color:#fff;
	}
	nav.navbar.shrink {
	  min-height: 35px;
	}
	nav.navbar.shrink { box-shadow:none; }
	nav.shrink .navbar-nav > li > a {
		padding-top: 26px;
		padding-bottom: 18px;
		-webkit-transition: all 750ms ease;
		transition: all 750ms ease;
	} 
	nav.shrink .navbar-nav > li:not(.cta) > a:before {
		height:3px;
		bottom:20%;
	}
	nav.shrink a.navbar-brand {
		/*
		padding-top:15px;
		padding-bottom:10px;
		*/
		height:auto;
	}
	nav.shrink .navbar-brand img {
		margin-top: -14px;
    	margin-bottom: -36px;
		-webkit-transition: all 750ms ease;
		transition: all 750ms ease;
	}
}
/*** NAVBAR ***/
/*** FOOTER ***/
#footer {
	padding:2em 0;
	color:#858585;
	background:#f5f5f5;
	position:relative;
	z-index:20;
}
#footer h4 {
	color:#272727;
	font-weight:bold;
	margin-bottom:2em;
}
@media screen and (max-width:767px) {
	#footer h4 { margin-bottom:1em; }
}
#footer .social {
	margin-bottom:1em;
}
#footer .social a {
	display:inline-block;
	width:40px;
	height:40px;
	background:#001128;
	color:#fff;
	text-align: center;
	font-size:24px;
	line-height:40px;
	border-radius:4px;
}
#footer .social a:hover { background:#294a69; }
#footer img.logo {
	margin-left:-0.5em;
	max-width:100%;
	width:215px;
}
#footer .sitemap {
	list-style:none;
	padding-left:0;
}
#footer .sitemap li { margin-bottom:.25em; }
#footer .sitemap li:last-child { margin-bottom:0; }
#footer .sitemap a { color:#858585; }
#footer .sitemap a:hover { color:#ef4b23; }



/*** PIECES ***/

/*** forms ***/
form .with-icon {
	position:relative;
}
form .with-icon input {
	padding-left:3.5em !important;
}
form .with-icon:before {
	font-family:'FontAwesome';
	position:absolute;
	top:8px; left:8px;
	font-size:24px;
	color:#8ba6c3;
	border-right:1px solid #8ba6c3;
	padding-right:8px;
	width:32px;
}
form .with-icon.search:before { content:"\f002"; }
form .with-icon.location:before { content:"\f124"; }
form .with-icon.calendar:before { content:"\f073"; }
form .with-icon.clock:before { content:"\f017"; }
form .with-icon.receipt:before { content:"\f0f6"; }

form .error-message { display:none; color:#ce8483; }
form .has-error .error-message { display:block;margin-top:.25em;font-size:90%; }
form.modern .form-group { overflow:hidden; }
form.modern input[type="text"],
form.modern input[type="email"],
form.modern input[type="tel"],
form.modern input[type="password"],
form.modern textarea,
form.modern select {
	background-color:transparent;
	border:1px solid #8ba6c3;
	border-radius:0;
	height:50px;
	padding:.5em 1em;
	width:100%;
	outline:none;
}
form.modern textarea {
	height:200px;
}
form.modern select.waiting,
form.modern select option.placeholder {
	color:#8ba6c3;
}
form.modern select option {
	color:#231f20;
}
form.modern input[type="text"]:focus,
form.modern input[type="email"]:focus,
form.modern input[type="tel"]:focus,
form.modern input[type="password"]:focus,
form.modern textarea:focus,
form.modern select:focus {
	border-color: #8ba6c3;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 17, 40, 0.6);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 17, 40, 0.6);
}
form.modern .has-error input[type="text"],
form.modern .has-error input[type="email"],
form.modern .has-error input[type="tel"],
form.modern .has-error input[type="password"],
form.modern .has-error textarea,
form.modern .has-error select {
	border-color: #843534;
}
form.modern .has-error input[type="text"]:focus,
form.modern .has-error input[type="email"]:focus,
form.modern .has-error input[type="tel"]:focus,
form.modern .has-error input[type="password"]:focus,
form.modern .has-error textarea:focus,
form.modern .has-error select:focus {
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
form.modern ::-webkit-input-placeholder { color: #8ba6c3; }
form.modern :-moz-placeholder { color: #8ba6c3; }
form.modern ::-moz-placeholder { color: #8ba6c3; }
form.modern :-ms-input-placeholder { color: #8ba6c3; }
form.modern .has-error ::-webkit-input-placeholder { color: #843534; }
form.modern .has-error :-moz-placeholder { color: #843534; }
form.modern .has-error ::-moz-placeholder { color: #843534; }
form.modern .has-error :-ms-input-placeholder { color: #843534; }
form.modern .has-error select.waiting,
form.modern .has-error select option.placeholder {
	color:#843534;
}

.wrap-checkbox input[type='checkbox'] {
	display:none;
}
.wrap-checkbox input[type='checkbox'] + label {
	display: inline-block;
	vertical-align: middle;
	font-weight:normal;
}
.wrap-checkbox input[type='checkbox'] + label:before {
	content:'\2713';
	display:inline-block;
	vertical-align: middle;
	width:25px;
	height:25px;
	line-height:25px;
	font-size:2rem;
	font-weight:bold;
	text-align: center;
	color:transparent;
	border:1px solid #8ba6c3;
	background-color:#294a69;
	margin:0 10px 0 0px;
}
.wrap-checkbox input[type='checkbox']:checked + label:before {
	color:#8ba6c3;
}
form.feedback .agree-to-terms.pad {
	padding:0 1em;
}


/*** alerts and thank you messages ***/
.alert-success {
	padding:1em;
	text-align:center;
}
/*** videos and other iframes ***/
.video-wrap {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
}
.video-wrap iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

/*** modals ***/
.modal .closer {
	position:absolute;
	top:0;
	right:0;
	width:30px;
	height:30px;
	line-height:30px;
	color:#fff;
	cursor:pointer;
	text-align:center;
	font-size:18px;
	z-index:9999;
	opacity:.7;
}
.modal .closer:hover {
	opacity:1;
}
.modal.for-video .closer { top:-28px; }
.modal.for-text .closer { color:#858585; }
.modal.for-video .modal-dialog {
	width:90%;
	max-width:1280px;
	margin:60px auto 0;
}
.modal.for-text .modal-dialog {
	width:90%;
	max-width:980px;
	margin:60px auto 0;
}
.modal.for-text .modal-header {
	padding-top:30px;
}
.modal.for-text .modal-content {
	background-color:#fff;
	border:none;
}
.modal.for-text .modal-body {
	max-height:550px;
	overflow-y:auto;
}
@media screen and (max-width:767px) {
	.modal.for-text .modal-body { max-height:420px; }
}
@media screen and (max-width:479px) {
	.modal.for-text .modal-body { max-height:300px; }
}

/*** toggles ***/
.toggle-control { margin:0 .25em; }
.toggle-control-wrap { margin:0 -.25em 1em; text-align:center; }
.toggle-control.btn {
	padding:.5em 2em;
	background-color:#858585;
	border:none !important;
	box-shadow:none !important;
}
.toggle-control.btn:hover {
	background-color:#ef4b23;
}
.toggle-control.btn.active {
	background:transparent;
	color:#ef4b23;
	pointer-events:none;
}

.toggle-content {
	height:0;
	overflow:hidden;
	-webkit-transition: all 500ms ease-out;
	transition: all 500ms ease-out;
}
.toggle-content.active {
	height:auto;
	opacity:1;
	transform:translateX(0);
}

.toggle-wrap { overflow:hidden; }
.toggle-left { transform:translateX(-100%);opacity:0; }
.toggle-right { transform:translateX(100%);opacity:0; }

/*** panels ***/
.panel-list .panel ol {
	padding-left:16px;
	margin-bottom:0;
}
@media screen and (min-width:768px) {
	.panel-list {
		overflow:hidden;
		margin-left:-1%;
		margin-right:-1%;
	}
	.panel-list .panel {
		width:48%;
		float:left;
		margin:.5em 1%;
	}
	.panel-list .panel:nth-child(odd) {
		clear:left;
	}
}

/*** feature lists ***/
.feature-list .item {
	overflow:hidden;
	padding:1.5em 0;
}
.feature-list.valign .image-wrap,
.feature-list.valign .content-wrap {
	display:inline-block;
	vertical-align:middle;
	float:none !important;
}
.feature-list.large p {
	font-size:125%;
}
.feature-list .image-wrap img {
	border-top-left-radius:4px;
	border-top-right-radius:4px;
}
.feature-list .content-wrap {
	padding:1em;
	border-bottom-left-radius:4px;
	border-bottom-right-radius:4px;
}
.feature-list .content-wrap.center { text-align:center !important; }
.feature-list .item .content-wrap h3 { color:#8ba6c3; }
.feature-list .item .content-wrap.bg-primary-lt h3 { color:#8ba6c3; }
.feature-list .item .content-wrap h3 b { text-transform:uppercase; }
@media screen and (min-width:600px) {
	.feature-list .item {
		margin-left:-1%;
		margin-right:-1%;
	}
	.feature-list .image-wrap {
		width:30%;
		float:left;
		margin:0 1%;
	}
	.feature-list .item:nth-child(even) .image-wrap {
		float:right;
	}
	.feature-list .image-wrap img {
		border-radius:4px;
	}
	.feature-list .content-wrap {
		width:63%;
		margin:0 1%;
		float:right;
		border-radius:4px;
	}
	.feature-list .item:nth-child(even) .content-wrap {
		float:left;
		text-align:right;
	}
}
/*** app download icons ***/
.download-icons.horizontal {
	margin:1em -1% 0;
	overflow:hidden;
}
.download-icons.horizontal > a {
	display:block;
	float:left;
	width:49%;
	margin:0 .5%;
}


/******************************
		SLIM VERSION
******************************/
body.slim { padding-top:0; }
body.slim-content #main {
	width:100%;
	float:none;
	padding-left:0;
}

.googlesearch input:focus + .googleresults { display: block }

.googlesearch .googleresults {
    display: none;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    background-color: #fdfdfd;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #eceef4));
    background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -ms-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: linear-gradient(top, #fdfdfd, #eceef4);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.googlesearch .googleresults li { display: block }

.googlesearch .googleresults li:first-child { margin-top: -1px }

.googlesearch .googleresults li:first-child:before, .googlesearch .googleresults li:first-child:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border: 5px outset transparent;
}

.googlesearch .googleresults li:first-child:before {
    border-bottom: 5px solid #c4c7d7;
    top: -11px;
}

.googlesearch .googleresults li:first-child:after {
    border-bottom: 5px solid #fdfdfd;
    top: -10px;
}

.googlesearch .googleresults li:first-child:hover:before, .googlesearch .googleresults li:first-child:hover:after { display: none }

.googlesearch .googleresults li:last-child { margin-bottom: -1px }

.googlesearch .googleresults a { 
    display: block;
    position: relative;
    margin: 0 -1px;
    padding: 6px 40px 6px 10px;
    color: #808394;
    font-weight: 500;
    text-shadow: 0 1px #fff;
    border: 1px solid transparent;
    border-radius: 3px;
}

.googlesearch .googleresults a span { font-weight: 200 }

.googlesearch .googleresults a:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    background: url("http://cssdeck.com/uploads/media/items/7/7BNkBjd.png") 0 0 no-repeat;
}
 
.googlesearch .googleresults a:hover {
    text-decoration: none;
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
    border-color: #2380dd #2179d5 #1a60aa;
    background-color: #338cdf;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #59aaf4), color-stop(100%, #338cdf));
    background-image: -webkit-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -moz-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -ms-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -o-linear-gradient(top, #59aaf4, #338cdf);
    background-image: linear-gradient(top, #59aaf4, #338cdf);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -o-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
}